<template>
  <b-card>
    <b-form>
      <b-row v-if="loyalty!=null">
        <b-col
          class="mt-1"
          md="12"
        >
      
      <h6 class="mb-0 ">
            {{ loyalty.title }}
            </h6>
            <span><small class="text-info "> 
              {{ loyalty.description }}   </small> </span>
  
      
        </b-col>
        <br/>
        <b-col
          class="mt-1"
          md="12" v-if="4>5"
        >
          <b-form-group
            label="Loyalty Event Type"
            label-for="isSecured"
          >
            <div class="demo-inline-spacing mb-1">
              <b-form-radio
                v-model="loyalty.isOnlyPoints"
                class="mt-0"
                :value="true"
              >
                Only Points
              </b-form-radio>
              <b-form-radio
                v-model="loyalty.isOnlyPoints"
                class="mt-0"
                :value="false"
              >
               Points Related to Purchase 
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group
           
            label="Point Amount"
          >
            <b-form-input
              v-model="loyaltySettings.purchaseAmount"
              placeholder="Point Amount"
              class="col-6"
            />
          </b-form-group>
         
        </b-col>
        <b-col md="3"   v-if="loyalty.isOnlyPoints == false">
         
          <div
        
            class="d-flex align-items-center flex-nowrap mt-2"
          >
            <span class="mr-2" >Equals to </span>
            <b-form-input
              v-model="loyaltySettings.pointAmount"
              class="col-4"
            />
            <span class="ml-1">SAR</span>
          </div>
        </b-col>
        <b-col md="12">
          <b-form-group
            label-for="account-quora"
            label="Expiration days"
          >
            <b-form-input
              v-model="loyaltySettings.expireDays"
              placeholder="Expire in days"
              class="col-2"
            />
          </b-form-group>
        </b-col>
 
   

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="updateLoyalty"
            class="mt-1 mr-1 "
          >
            Update
          </b-button>

        </b-col>
        <!--/ buttons -->

        <b-col cols="12">
          <hr class="my-2">
        </b-col>

      </b-row>
    </b-form>
    <div class="m-2" />

    <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="loyaltyHistories"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-button @click="edit(data)">
          Edit
        </b-button>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <!-- <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination> -->

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BLink, BAvatar, BFormRadio, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import clientSettingsStoreModule from './clientSettingsStoreModule'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useToast } from 'vue-toastification/composition'
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BLink,
    BAvatar,
    BFormRadio,
    BTable,
  },
  directives: {
    Ripple,
  },
  props: {
    smtpsettings: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const clientData = ref(null)
    const loyaltyData = ref(null)
    const loyalty = ref(null)

    const loyaltySettings = ref(null)

    const toast = useToast()


    const USER_APP_STORE_MODULE_NAME = 'apps-clients-settings'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, clientSettingsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('apps-clients-settings/fetchLoyaltySettings' )
      .then(response => {
        //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
        
        console.log('fetchLoyaltySettings')
        console.log(response.data.data);

        loyaltyData.value= response.data.data ;

        console.log('=========')

        // console.log(response.data);
      })
      .catch(error => {
        if (error.response.status === 404) {
          clientData.value = undefined
        }
      })
     


    const updateLoyalty= ()=> {
      loyalty.value.loyaltySettings=loyaltySettings.value; 
      console.log("loyaltySettings in update:",loyaltySettings.value);
      return  store.dispatch('apps-clients-settings/updateLoyaltySettings',loyalty.value )
      .then(response => {
        //    var course=JSON.parse(JSON.stringify(response.data));
      //  const { course  } = response.data
        
        console.log('updateLoyaltySettings')
        console.log(response.data.data);
        loyaltySettings.value=response.data.data;
          console.log("loyalty: ",loyalty);
          console.log("loyaltyData: ",loyaltyData);
        loyaltyData.value.loyaltySettings=loyaltySettings.value; 
        loyalty.value.ClientLoyaltyEvents= [  loyaltySettings.value] ;
  
          toast({
          component: ToastificationContent,
          props: {
            title: 'Setting updated',
            icon: 'AlertSuccessIcon',
            appendToast: true,
            solid:true,
            variant: 'success',
          },
        })
        //loyaltyData.value= response.data.data ;

        console.log('=========')

        // console.log(response.data);
      })
      .catch(error => {
       
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching courses list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        
      })
    };
      const { t } = useI18nUtils()
    return {
      loyaltySettings,
      loyalty,
      updateLoyalty,
      loyaltyData,
      clientData,
      t
    }
  },
  data() {
    console.log(this.smtpsettings)

    return { 
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      totalUsers: 5,
      isSortDirDesc: false,
      tableColumns: [
        { key: 'title', sortable: true },
        { key: 'isOnlyPoints', sortable: true },
        { key: 'pointAmount', sortable: true }, 
        { key: 'expireDays', sortable: true }, 
        { key: 'required',label:"Requires Approval", sortable: true },
        { key: 'actions' },
      ],
      sortBy: 'id',
      localSettings: {},
      isLoading: false,
      isUpdated: false,
      clevePort: {
        numeral: true,

      },

    }
  },
  computed: {
    loyaltyIsOnlyPoints(){
      return this.loyalty.isOnlyPoints===1?1:0;

    },
    loyaltyHistories() {
      console.log("Computed");
      console.log(this.loyaltyData);
      return this.loyaltyData;
    },
    
    loyaltyHistoriesOld() {
      return [
        {
          id: 1,
          eventName: 'Verify Email',
          eventType: 'Only Point',
          pointAmount: '100',
          paid: '-',
          expireDays: 3,
        },
        {
          id: 2,
          eventName: 'Verify Phone',
          eventType: 'Only Point',
          pointAmount: '100',
          paid: '-',
          expireDays: 3,
        },
        {
          id: 3,
          eventName: 'Writing Review',
          eventType: 'Only Point',
          pointAmount: '100',
          paid: '-',
          expireDays: 3,
        },
        {
          id: 4,
          eventName: 'Sharing Experience',
          eventType: 'Only Point',
          pointAmount: '100',
          paid: '-',
          expireDays: 3,
        },
        {
          id: 5,
          eventName: 'Subscribing to Newsletter',
          eventType: 'Only Point',
          pointAmount: '100',
          paid: '-',
          expireDays: 3,
        },
        {
          id: 6,
          eventName: 'Referal Registration',
          eventType: 'Only Point',
          pointAmount: '100',
          paid: '-',
          expireDays: 3,
        },
        {
          id: 7,
          eventName: 'Buying Course',
          eventType: 'Purchase Point',
          pointAmount: '-',
          paid: '100',
          expireDays: 3,
        },
      ]
    },
    dataMeta() {
      const localItemsCount = 5
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
  },
  methods: {
    edit($event) {
      const item = { ...$event.item }
      console.log(item.event)
      console.log("Printed Item");
      if (item.eventType === 1) {
        item.eventType = 1
      } else {
        item.eventType = 0
      }
      this.loyalty = item
      if (item.ClientLoyaltyEvents==null||item.ClientLoyaltyEvents==undefined||item.ClientLoyaltyEvents.length==0)
      {
        this.loyaltySettings={
          "expireDays":0,
          "status":100,
          "pointAmount":0,
          "purchaseAmount":0,"required":0,



        };
      }
      else
      {
        this.loyaltySettings=   JSON.parse(JSON.stringify( item.ClientLoyaltyEvents[0]));
    
      }
      console.log("Print Selected Item:",item);
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
