import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClientSettings(ctx, queryParams) {
      console.log("ClientSettings Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/auth/f/settings', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLoyaltyAwards(ctx, queryParams) {
      console.log("ClientSettings Fetching");
     
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/auth/f/clientloyaltyawards' )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateLoyaltyAwards(ctx, queryParams) {
     console.log("Incoming object");
      var id=queryParams.id;
      console.log("id: ",id);
    console.log("loyalty settings: ",queryParams.loyaltySettings);
     var loyalSettings=queryParams.loyaltySettings;


      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/auth/f/clientloyaltyawards/${id}`,loyalSettings )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLoyaltySettings(ctx, queryParams) {
      console.log("ClientSettings Fetching");
     
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/auth/f/getclientloyaltyevents' )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateLoyaltySettings(ctx, queryParams) {
     console.log("Incoming object");
      var id=queryParams.id;
      console.log("id: ",id);
    console.log("loyalty settings: ",queryParams.loyaltySettings);
     var loyalSettings=queryParams.loyaltySettings;


      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/auth/f/clientloyaltyevents/${id}`,loyalSettings )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClientSetting(ctx, { id }) {
      console.log("single ClientSetting  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/ClientSetting/getClientSettingdetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateClientSettingInfo(ctx, { id }) {
      console.log("update ClientSetting  is launching with id : "+id);
      return ;
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/ClientSetting/getClientSettingdetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

   
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/ClientSetting/ClientSettings/ClientSettings', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
