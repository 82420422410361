<template>
    <b-card>
      <b-form>
        <b-row >

          <b-col
            class="mt-1"
            md="12"
          >
        
        <h6 class="mb-0 ">
            LOYALTY AWARDS
              </h6>
              <span><small class="text-info "> 
                {{ loyalty.description }}   </small> </span>
    
        
          </b-col>
          <br/>
        
   <br/>



   <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="getLoyalties"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
    <template #cell(status)="data">
        <b-form-checkbox
      v-model="data.item.isActive"  true-value="true" false-value="false"
      value="true"
      class="custom-control-success"
    >
      Active
    </b-form-checkbox>
    </template>
    <template #cell(usePercentage)="data">
        <b-form-checkbox
      v-model="data.item.usePercentage"
      value="true"
      class="custom-control-success"
    >
   
    Use Percent 
    </b-form-checkbox>
    </template>
    <template #cell(pointAmount)="data">
       
    <b-form-input
                v-model="data.item.pointAmount"
                placeholder="1000" 
              />
     
    </template>
    <template #cell(pointPercentRate)="data">
       
       <b-form-input
                   v-model="data.item.pointPercentRate"
                   placeholder="1" 
                 />
      
       </template>
    <template #cell(pointEqualtoMoney)="data">
       
    <b-form-input
                v-model="data.item.pointEqualtoMoney"
                placeholder="100" 
              />
   
    </template>

    <template #cell(ratemoney)="data">
       
     
                 <b-form-input
                 v-if="data.item.usePercentage==false"
                   v-model="data.item.pointPercentRate"
                   placeholder="1" 
                 />
                 <b-form-input
       v-else  
                   v-model="data.item.pointEqualtoMoney"
                   placeholder="100" 
                 />
                
      
       </template>

    
    <template #cell(actions)="data">
      <b-button  @click="updateAward(data)"
        
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary" 
              class="mt-1 mr-1 "
            >
              Update
            </b-button>
 
      </template>
     

    </b-table>
 
  
        </b-row>
      </b-form>
     
    </b-card>
  </template>
  
  <script>
  import {
    BFormCheckbox, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BLink, BAvatar, BFormRadio, BTable,
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import { ref, onUnmounted } from '@vue/composition-api'
  import store from '@/store'
  import clientSettingsStoreModule from './clientSettingsStoreModule'
  import { useUtils as useI18nUtils } from '@core/libs/i18n'
  import { useUtils as useAclUtils } from '@core/libs/acl'
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
import axios from "@axios";


  import { useToast } from 'vue-toastification/composition'
  export default {
    components: {
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BLink,
      BAvatar,
      BFormRadio,
      BTable,
      BFormCheckbox
    },
    directives: {
      Ripple,
    },
    props: {
      smtpsettings: {
        type: Object,
        default: () => {},
      },
    },
    setup() {
      const clientData = ref(null)
      const loyaltyData = ref(null)
      const loyalty = ref({})
  
      const loyaltySettings = ref({})
  
      const toast = useToast()
  
  
      const USER_APP_STORE_MODULE_NAME = 'apps-clients-settings'
  
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, clientSettingsStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      })
  
    const getLoyalties=  (ctx, callback) => {  
        store.dispatch('apps-clients-settings/fetchLoyaltyAwards' )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
          console.log('fetchLoyaltyAwards')
          console.log(response.data.data);
          var serverLoyalties=response.data.data;
          for (let index = 0; index < serverLoyalties.length; index++) {
             var awardsettingsData=[];

            const element = serverLoyalties[index];
            const clientSettings={};
            if (element.ClientLoyaltyAwards.length>0)
            {
                clientSettings= element.ClientLoyaltyAwards[0];
                console.log("Found Client Award");
                clientSettings.id=element.id;
                clientSettings.title=element.title;


            }
            else
            {
                clientSettings=element;
            }
            awardsettingsData.push(clientSettings);
          }
  
          loyaltyData.value= awardsettingsData ;
          console.log("sending award Data",awardsettingsData);
  
          console.log('=========')
          callback(awardsettingsData );
  
          // console.log(response.data);
        })
        .catch(error => {
          if (error.response.status === 404) {
            clientData.value = undefined
          }
        })

    }
       
  
  
      const updateLoyalty= ()=> {
        loyalty.value.loyaltySettings=loyaltySettings.value; 
        console.log("loyaltySettings in update:",loyaltySettings.value);
        return  store.dispatch('apps-clients-settings/updateLoyaltyAwards',loyalty.value )
        .then(response => {
          //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
          
          console.log('updateLoyaltySettings')
          console.log(response.data.data);
          loyaltySettings.value=response.data.data;
            console.log("loyalty: ",loyalty);
            console.log("loyaltyData: ",loyaltyData);
          loyaltyData.value.loyaltySettings=loyaltySettings.value; 
          loyalty.value.ClientLoyaltyEvents= [  loyaltySettings.value] ;
    
            toast({
            component: ToastificationContent,
            props: {
              title: 'Setting updated',
              icon: 'AlertSuccessIcon',
              appendToast: true,
              solid:true,
              variant: 'success',
            },
          })
          //loyaltyData.value= response.data.data ;
  
          console.log('=========')
  
          // console.log(response.data);
        })
        .catch(error => {
         
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching courses list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          
        })
      };
        const { t } = useI18nUtils()
      return {
        getLoyalties,
        loyaltySettings,
        loyalty,
        updateLoyalty,
        loyaltyData,
        clientData,
        t
      }
    },
    data() {
      
  
      return { 
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        searchQuery: '',
        totalUsers: 5,
        isSortDirDesc: false,
        tableColumns: [
        { key: 'Status' },
          { key: 'title', sortable: true },
          { key: 'usePercentage', label:"Use Percent", sortable: true },
          
          { key: 'pointAmount', sortable: true },
          { key: 'ratemoney',label:"rate", sortable: true }, 
           { key: 'actions' },
        ],
        sortBy: 'id',
        localSettings: {},
        isLoading: false,
        isUpdated: false,
        clevePort: {
          numeral: true,
  
        },
  
      }
    },
    computed: {
      loyaltyIsOnlyPoints(){
        return this.loyalty.isOnlyPoints===1?1:0;
  
      },
      loyaltyHistories() {
        console.log("Computed");
        console.log(this.loyaltyData);
        return this.loyaltyData;
      },
      
      loyaltyHistoriesOld() {
        return [
          {
            id: 1,
            eventName: 'Verify Email',
            eventType: 'Only Point',
            pointAmount: '100',
            paid: '-',
            expireDays: 3,
          },
          {
            id: 2,
            eventName: 'Verify Phone',
            eventType: 'Only Point',
            pointAmount: '100',
            paid: '-',
            expireDays: 3,
          },
          {
            id: 3,
            eventName: 'Writing Review',
            eventType: 'Only Point',
            pointAmount: '100',
            paid: '-',
            expireDays: 3,
          },
          {
            id: 4,
            eventName: 'Sharing Experience',
            eventType: 'Only Point',
            pointAmount: '100',
            paid: '-',
            expireDays: 3,
          },
          {
            id: 5,
            eventName: 'Subscribing to Newsletter',
            eventType: 'Only Point',
            pointAmount: '100',
            paid: '-',
            expireDays: 3,
          },
          {
            id: 6,
            eventName: 'Referal Registration',
            eventType: 'Only Point',
            pointAmount: '100',
            paid: '-',
            expireDays: 3,
          },
          {
            id: 7,
            eventName: 'Buying Course',
            eventType: 'Purchase Point',
            pointAmount: '-',
            paid: '100',
            expireDays: 3,
          },
        ]
      },
      dataMeta() {
        const localItemsCount = 5
        return {
          from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
          to: this.perPage * (this.currentPage - 1) + localItemsCount,
          of: this.totalUsers,
        }
      },
    },
    methods: {

        updateAward($event) {
                    console.log($event);
                    const item = { ...$event.item }
                    this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/auth/f/clientloyaltyawards/${item.id}`,
        data: item,
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
        },
        sendInformation() {
      

      this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/auth/f/settings`,
        data: {
          info: "loyaltyawards",

          content: this.localSettings,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
      edit($event) {
        const item = { ...$event.item }
        console.log(item.event)
        console.log("Printed Item");
        if (item.eventType === 1) {
          item.eventType = 1
        } else {
          item.eventType = 0
        }
        this.loyalty = item
        if (item.ClientLoyaltyEvents==null||item.ClientLoyaltyEvents==undefined||item.ClientLoyaltyEvents.length==0)
        {
          this.loyaltySettings={
            "expireDays":0,
            "status":100,
            "pointAmount":0,
            "purchaseAmount":0,"required":0,
  
  
  
          };
        }
        else
        {
          this.loyaltySettings=   JSON.parse(JSON.stringify( item.ClientLoyaltyEvents[0]));
      
        }
        console.log("Print Selected Item:",item);
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  