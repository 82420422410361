<template>
  <b-row>

    <!-- Small -->
     

    <!-- Large -->
    <b-col cols="12">
     
        <v-select  v-if="categories!=null"
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="initialCategories" 
          
          class="select-size-lg"
       >
             <template #option="{ title ,description }">
             
                <span> {{ title }}</span>
              <span> {{ description }}</span>
            </template>
          </v-select>
    
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'

import axios from '@axios'
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  created () {
    this.fetchCategories()
  
  },
  props :{
    initialCategories:[]

  },


  methods:{
     updateSelections(a) {
    
    console.log(a)
  },
         fetchCategories( ) {
      console.log("fetchCategories fetch");
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/auth/f/getmailtemplates' )
          .then((response)=> { 
           
         
           var data=  response.data;
           var responseData=JSON.parse(  JSON.stringify( data));
       
              this.categories=responseData.data;
this.selected=this.categories[0];
          

         

            }
            )
          .catch(error => reject(error))
      })
    },
  },
  watch: {
  selected: function (newVal,coldVal) {
      this.$emit("selectionChanged", newVal);

  }
},
  data() {
    return {
      categories:null,
      selected: this.initialCategories,
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
    }
  }
   

}
</script>