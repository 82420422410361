<template>
  <b-card>
    <b-form>
      <b-row>
        
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="GlobeIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Regional Settings
            </h4>
          </div>
        </b-col>
           <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
             
            <h6 class="mb-0 ">
             This informations will be used whole over the platform. Be careful about that you have choosed correct values
            </h6>
          </div>
        </b-col>

          <b-col class="mt-1" md="6">
          <b-form-group
            label-for="country"
            label="Country"
          >
            <country-picker @countrySelected="countrySelected" :initialCategories="localSettings.country"></country-picker>
          </b-form-group>
        </b-col>
  <b-col class="mt-1" md="6">
          <b-form-group
            label-for="curreny"
            label="Default Currency"
          >
           <currency-picker @countrySelected="currencySelected" :initialCategories="localSettings.currency" ></currency-picker>

          </b-form-group>
        </b-col>
           
        <!--/ quora -->

        <b-col cols="12">
          <hr class="my-2">
        </b-col>

        <!-- Profile Connections -->
       
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="sendInformation"
          >
            Save changes
          </b-button>
         
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar,  BFormRadio,   BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from "@axios";
import { ref } from "@vue/composition-api";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import  CountryPicker from "./component/SingleCountryPicker.vue";
import CurrencyPicker from './component/CurrenyPicker.vue';

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
     BCardText,
    BFormRadio,
        BFormCheckbox,
        CountryPicker,
        CurrencyPicker
  },
  directives: {
    Ripple,
  },
  props: {
    clientdata: {
      type: Object,
      default: () => {},
    },
  },
   created() {
     console.log("regional settings created");
     try {
      this.localSettings = this.clientdata.data==null||JSON.parse(JSON.stringify(this.clientdata.data)).regionalsettings ==null?{}: JSON.parse(JSON.parse(JSON.stringify(this.clientdata.data)).regionalsettings );//his.generalData;
   
     } catch (error) {
       console.log(error);
     }
   
   


  },
  data() {
 

    return {
      localSettings: {
        country :null
      },
      countryInfo:{},
      currencyInfo:{},
            isLoading: false,
             isUpdated: false,
             clevePort: {
         numeral: true
      
      },
    
    }
  },
  methods: {

     countrySelected(payload) {
      console.log(payload);
      this.localSettings.country =   payload;
      
    },
     currencySelected(payload) {
      console.log(payload);
      this.localSettings.currency =   payload;
      
    },

     sendInformation() {
      console.log(this.localSettings);
      console.log("update regional settings  is launching with id : " + this.courseId);

      

      this.isLoading = true;
      axios({
        method: "put",
        url: `/admin/auth/f/settings`,
        data: {
          info: "regionalsettings",
          file: null,
          content: {
            country: this.localSettings.country,
            currency: this.localSettings.currency, 
          },
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.smtpsettings))
    },
  },
}
</script>
